import cn from "classnames";
import { round } from "lodash";
import React, { useState } from 'react'
import { useModal } from "../hooks/modal";
import { usePostRequest } from '../hooks/request'
import ModalCarAdditional from "../layouts/modals/ModalCarAdditional";
import ModalCarConfirm from "../layouts/modals/ModalCarConfirm";
import ModalCarInfo from "../layouts/modals/ModalCarInfo";
import ModalDocInfo from "../layouts/modals/ModalDocInfo";
import cross from '../static/img/cross.svg'
import jpgFile from '../static/img/icon-jpg-ye.svg'
import photo from '../static/img/photo.svg'
import rightArrow from '../static/img/right-arrow.svg'
import { carUpdateData } from "../urls"
import Loader from "./common/Loader";


export default function CarUpdatePhotoForm({onCancel, onSuccess, id = '',}) {
  const [ showInfoForm, hideInfoForm ] = useModal(<ModalCarInfo close={ () => {hideInfoForm()} }/>)
  const [ showDocForm, hideDocForm ] = useModal(<ModalDocInfo close={ () => {hideDocForm()} }/>)
  const [ showInfoAdd, hideInfoAdd ] = useModal(<ModalCarAdditional close={ () => {hideInfoAdd()} }/>)
  const [ showConfirmForm, hideConfirmForm ] = useModal(
    <ModalCarConfirm close={ () => {hideConfirmForm()} } approve={ () => onSubmit(photo) }/>
  )

  const {request, loading} = usePostRequest({
    url: carUpdateData,
  })

  const [ photos, setPhotos ] = useState({})
  const [ keys, setKeys ] = useState([ 1, 2, 3, 4, 5, 6 ])
  const [ successResponse, setSuccessResponse ] = useState(false)
  const [ sizeError, setSizeError ] = useState(false)

  function addPhoto(event) {
    if (event.target.files[0].size < 10485760) {
      const newKeys = [ ...keys ]
      setPhotos({...photos, [newKeys.shift()]: event.target.files[0]})
      setKeys(newKeys)
    } else {
      setSizeError(true)
      window.setTimeout(() => setSizeError(false), 5000)
    }
  }

  function deletePhoto(item) {
    const newKeys = [ ...keys ]
    newKeys.push(item)
    newKeys.sort()
    setPhotos({...photos, [item]: null})
    setKeys(newKeys)
  }

  function validate() {
    const errorFiles = {}
    keys.forEach((item) => {errorFiles[item] = false})
    setPhotos({...photos, ...errorFiles})
    return Object.keys(errorFiles).length === 0
  }

  async function onSubmit() {
    hideConfirmForm()
    const data = new FormData()
    data.append('policy_number', id)

    Object.keys(photos).forEach((key) => {
      data.append(`files`, photos[key])
    })

    const {success, response} = await request({data})
    if (success) {
      setSuccessResponse(true)
      if (onSuccess) {
        onSuccess(response)
      }
    }

    if (!success && onCancel) {
      onSuccess(response)
    }
  }

  if (loading)
    return <Loader/>

  return (
    <>
      { sizeError &&
        <div style={ {color: 'rgb(171,8,8)', textAlign: 'center', fontSize: '32pt', fontWeight: 'bolder'} }>Файл больше
          10 Мб</div> }
      { successResponse && <div style={ {
        color: 'rgb(27,185,9)',
        textAlign: 'center',
        fontSize: '32pt',
        fontWeight: 'bolder',
        marginTop: '120px'
      } }>Загрузка завершена успешно</div> }
      <div className="main-info tabs" role="tablist">
        <div className="tabs__nav">
          <button className="tabs__btn tabs__btn_active" data-index="0" role="tab">
            Приложить фото ({ keys.length })
          </button>
        </div>
        <div className="tabs__content">
          <div className="tabs-cell tabs__cell tabs__cell_show">
            <div className="tabs-cell__wrapper">
              {
                [ 1, 2, 3, 4, 5, 6 ].map((item) => (
                  <div key={ item }
                       className={ cn('tabs-cell__image-box image-box', {
                         'image-box-filled': photos[item],
                         'image-box-warning': photos[item] === false,
                       }) }
                  >
                    <span className="image-box-text">Фото { item }</span>
                    {
                      photos[item] && (
                        <div className="image-box__fill">
                          <img src={ jpgFile } alt="" width="32px"/>
                          <div className="image-box__content">
                            <p className="image-box__title">
                              { photos[item].name }
                            </p>
                            <p className="image-box__size">
                              { round(photos[item].size / 1024) }кб
                            </p>
                          </div>
                          <img onClick={ () => deletePhoto(item) }
                               className="image-box__close" src={ cross } alt="" width="16px"/>
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </div>
            <div className="tabs-cell__text">
              <div className="tabs-cell__info-text">
                Необходимо добавить фото автомобиля с 4х сторон
                <button onClick={ () => {showInfoForm()} }
                        className="tabs-cell__info-link modal-photos" id="modal-photos">
                  (пример)
                </button>
                и свидетельство АТС с двух сторон
                <button onClick={ () => {showDocForm()} }
                        className="tabs-cell__info-link modal-photos" id="modal-photos">
                  (пример)
                </button>
              </div>
              <button onClick={ () => {showInfoAdd()} }
                      className="tabs-cell__info-link modal-photos" id="modal-photos">
                ТРЕБОВАНИЯ К ФОТОГРАФИЯМ
              </button>
            </div>
            <label className="tabs-cell__button btn btn-light">
              Сфотографировать
              <input type="file" accept="image/*" multiple={ false } capture="camera" style={ {display: "none"} }
                     onChange={ addPhoto }/>
              <img className="btn__img btn__photo" src={ photo } alt="" width="20px"/>
            </label>
          </div>
        </div>
        <button
          className="info__button btn modal-approve" id="modal-approve"
          onClick={ (e) => {
            e.stopPropagation()
            if (validate()) {
              showConfirmForm()
            }
          }
          }>
          Обновить данные
          <img className="btn__img" src={ rightArrow } alt="" width="10px"/>
        </button>
      </div>
    </>
  )
}
